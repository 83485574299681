import React from "react";
import {  Box } from "grommet";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Head() {
  return (
    <Box basis="full" align="center" margin={{top: "large"}} pad={{vertical:"xsmall"}} justify="center" direction="row">
      <Box direction="row" align="center" gap="large" >
        <Link to="/">
          <StaticImage
            width={150}
            alt="together we're better (twb) logo"
            src="../static/images/twbLogo.svg"
          />
        </Link>
      </Box>
    </Box>
  );
}
