import React from "react";
import "@fontsource/dm-sans/400.css";
import "@fontsource/dm-sans/500.css";
import {
  Box,
  Grommet,
  Main
} from "grommet";

import po3theme from "../themes/po3Theme.json";
import Head from "./head.js";

import "../static/assets/styles.css";

const Layout = ({ ...children }) => {
  return (
    <Grommet theme={po3theme}>
      <Box>
        <Box
          width="xxlarge"
          fill
          pad={{  horizontal: "medium" }}
        >
          <Head />
        </Box>
        <Main pad={{horizontal: "medium"}} responsive={true}>{children.children}</Main>
        <Box as="footer"></Box>
      </Box>
    </Grommet>
  );
};

export default Layout;
